@import "./variables.scss";
.bottom-section {
  .bottom-section-net {
    width: 100%;
    top: 50%;
    bottom: 0;
    @apply absolute    left-1/2 transform -translate-x-1/2 overflow-hidden max-w-1440;
    overflow: hidden;
  }
  .other-cards {
    z-index: 0;

    border-radius: 8px;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
  }
  .r {
    transform: translateX(50%) translateY(-50%);
  }
  .other-cards-l-1 {
    top: 50%;
    left: 13%;
    width: 35%;
    z-index: 3;
  }
  .other-cards-l-2 {
    top: 50%;
    left: 7.4%;
    width: 30%;
    z-index: 2;
  }
  .other-cards-l-3 {
    top: 50%;
    left: 3%;
    width: 25%;
    z-index: 1;
  }
  .other-cards-r-1 {
    top: 50%;
    right: 13%;
    width: 35%;
    z-index: 3;
  }
  .other-cards-r-2 {
    top: 50%;
    right: 7.4%;
    width: 30%;
    z-index: 2;
  }
  .other-cards-r-3 {
    top: 50%;
    right: 3%;
    width: 25%;
    z-index: 1;
  }
  @media (min-width: 1024px) {
  }

  @media (min-width: 1280px) {
  }
}
