#about-us {
  // background: rgba(0, 0, 0, 0.2);
  // background: linear-gradient(180deg, #ff8413 0%, #d37927 100%);
  padding-top: 40px;
  padding-bottom: 40px;
  .heading,
  .subheading {
    text-align: center;
  }

  @media (min-width: 1024px) {
    padding-bottom: 40px;
    margin-bottom: 40px;
    .heading,
    .subheading {
      text-align: left;
    }
  }
}
