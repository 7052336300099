@import "./variables.scss";

#team {
  padding: 40px 0;
  background: linear-gradient(180deg, #d37927 0%, #b65700 100%);
  color: white;
  .team-grid {
    padding-top: 40px;
    @apply grid grid-cols-1  gap-6 mt-6 lg:grid-cols-4 lg:grid-rows-1 lg:mt-10;
  }
  .hover {
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.5s;
    transform: scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  .team-member .hover:hover {
    transform: scale(1.05) rotateX(14deg) rotateY(0deg) rotateZ(10deg);
  }
  .team-member:nth-of-type(2n) .hover:hover {
    transform: scale(1.05) rotateX(-14deg) rotateY(0deg) rotateZ(-10deg);
  }
  .team-name {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .team-pos {
    font-size: 14px;
    font-weight: 700;
  }
  .icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #67abe8;
    padding: 8px;
    transition: transform 0.3s;
  }
  .icon:hover {
    transform: scale(0.9) rotate(4deg);
  }
  .icon-fb {
    background: #3e5996;
  }
  .icon-fb:hover {
    transform: scale(0.9) rotate(-4deg);
  }
  @media (min-width: 1024px) {
    padding: 50px 0 80px 0;
    .team-name {
      font-size: 14px;
    }
    .team-pos {
      font-size: 10px;
    }
  }
  @media (min-width: 1280px) {
    .team-name {
      font-size: 24px;
    }
    .team-pos {
      font-size: 14px;
    }
  }
}
