@import "./variables.scss";
#header {
  width: 100%;

  transition: all 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  @media (min-width: 1024px) {
  }

  @media (min-width: 1280px) {
  }
}
