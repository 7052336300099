@import "./variables.scss";
.app {
  .gradient-bg {
    background: linear-gradient(180deg, #ff8413 0%, #fd8517 71.87%);
  }
  .btn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 1;
    // width: 100%;
    font-size: 14px;
    border-radius: 8px;
    height: 100%;
    max-height: 60px;
    padding: 14px 16px 10px !important;
    color: #fff;
    background: $lime;
    font-weight: 800;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 24px 45px -23px rgba(255, 210, 50, 0.4);
  }
  .btn:hover {
    background: rgb(255, 206, 46);
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 24px 55px -13px rgba(255, 210, 50, 0.4);
  }
  .btn-mint {
    background: rgb(255, 206, 46);
  }
  .btn-mint:hover {
    background: $lime;
  }
  .outlined {
    border: 1px solid white;
    background: transparent;
  }
  .ghost {
    position: absolute;
    right: 10%;
    top: 700px;
    width: 20%;
    z-index: 10;
  }
  .web {
    z-index: 0 !important;
    position: absolute;
    left: 0;
    transform: translateX(-50%) translateY(-50%);
    top: 30%;
    width: 30%;
    z-index: 10;
  }
  .web-r {
    z-index: 0 t;
    position: absolute;
    right: 0;
    transform: translateX(50%) translateY(50%);
    bottom: 20%;
    width: 40%;
    z-index: 10;
  }
  .logo {
    cursor: pointer;
    max-width: 100px;
  }
  .anchor {
    text-transform: uppercase;
    min-width: max-content;
    @apply cursor-pointer  font-bold  transform transition-all;
  }
  .anchor:hover {
    color: $lime;
    transform: scale(1.1);
  }
  .socials-icon {
    display: block;
    color: $lime;
    transition: all 0.2s ease-in-out;
  }
  .socials-icon:hover {
    transform: scale(1.1);
    color: rgb(255, 206, 46);
  }

  .bottom-gradient {
    background: linear-gradient(180deg, #d66703 0%, #fd8517 100%);
  }
  .net-gradient {
    @apply h-full w-20 top-0  absolute bg-black;
    background: linear-gradient(90deg, $orange 0%, transparent 100%);
  }
  .net-gradient.gradient-r {
    background: linear-gradient(-90deg, $orange 0%, transparent 100%);
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1280px) {
  }
}
