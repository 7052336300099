@import "./variables.scss";
#roadmap {
  // background: linear-gradient(180deg, #b65700 0%, #d66703 100%);
  padding: 60px 0;
  .roadmap-month {
    top: 50%;
    color: white;
    width: max-content;
    line-height: 1;
    display: block;
    position: absolute;

    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-weight: 600;
    font-size: clamp(20px, 150%, 34px);
  }
  .roadmap-line {
    display: none;
  }

  .roadmap-month > span {
    font-size: 24px;
  }
  // .month-1 {
  //   top: 50%;
  // }
  .month-5 {
    color: white;
  }
  .month-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
  }
  .month-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 34px;
  }
  .milestone img {
    transition: all 0.5s;
  }
  .milestone:hover img {
    transform: rotate(3deg) scale(1.05);
  }
  @media (min-width: 1024px) {
    .month-heading {
      font-size: 16px;
      line-height: 34px;
    }
    .month-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 34px;
    }
    .roadmap-month {
    }
    .month-5 {
      .month-text {
        line-height: 20px;
      }
    }
    .roadmap-line {
      display: block;
      // box-sizing: content-box;
      border-radius: 65px;
      border: 8px solid #ffad1f;
      position: absolute;
      width: calc(100% + 28px);
      height: 31%;

      &.top {
        height: 31%;
        bottom: 38%;
        transform: translateY(50%);
        border-top: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
      &.bottom {
        top: 35%;
        transform: translateY(-50%);
        border-bottom: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  @media (min-width: 1280px) {
    padding: 90px 0;
    .month-heading {
      font-size: 100%;
      line-height: 34px;
    }
    .month-text {
      font-size: 80%;
      line-height: 34px;
    }
    .roadmap-month {
    }
  }
}
