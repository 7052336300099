.sp_container {
  margin: 0 auto;
  width: 250px;
  height: 200px;
  position: relative;
  perspective: 1000px;
}

.carousel {
  height: 100%;
  width: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.item {
  display: block;
  position: absolute;
  background: #000;
  width: 250px;
  height: 200px;
  line-height: 200px;
  font-size: 5em;
  text-align: center;
  color: #fff;
  opacity: 0.95;
  border-radius: 10px;
}

.a {
  transform: rotateY(0deg) translateZ(250px);
}

.b {
  transform: rotateY(60deg) translateZ(250px);
}
.c {
  transform: rotateY(120deg) translateZ(250px);
}
.d {
  transform: rotateY(180deg) translateZ(250px);
}
.e {
  transform: rotateY(240deg) translateZ(250px);
}
.f {
  transform: rotateY(300deg) translateZ(250px);
}

.next,
.prev {
  color: white;
  position: absolute;
  top: 310px;
  padding: 0.5em 1em;
  cursor: pointer;
  background: #c9e606;
  border-radius: 5px;

  transition: all 0.3s;
}
.next:hover,
.prev:hover {
  background: rgb(255, 206, 46);
}
.next:active,
.prev:active {
  top: 310px;
  box-shadow: 0 1px 0 #999;
}
.next {
  right: 20%;
}
.prev {
  left: 20%;
}
