@import "./variables.scss";
#hero-section {
  overflow: hidden;
  padding-top: 140px;
  max-height: 854px;
  display: flex;
  // background: linear-gradient(180deg, #623002 0%, #fd8517 71.87%);
  align-items: center;
  .hero-section-net {
    width: 200%;
    top: 80%;
    bottom: 0;
    @apply absolute    left-1/2 transform -translate-x-1/2 overflow-hidden max-w-1440;
    overflow: hidden;
  }
  .welcome-to {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .connect-wallet {
    height: 74px;
  }
  .connect-wallet .btn {
    font-size: 20px !important;
  }
  .title:first-child {
    color: #fff;
  }
  .title {
    line-height: 1;
    color: white;
    font-weight: 900;
    font-size: 32px;
    text-transform: uppercase;
  }
  .title-big {
    color: $lime;
    font-size: 45px;
  }
  .timer {
    padding: 13px 19px;
    width: 314px;
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    place-content: center;
  }

  .timer-digits {
    font-size: 28px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
  }
  .timer-date {
    font-size: 8px;
    font-weight: 400;
    color: white;
    @media (min-width: 1024px) {
      font-size: 10px;
    }
  }
  .splash {
    padding: 40px 0;
    align-self: flex-end;
    width: 100%;

    display: block;
  }
  .splash-next,
  .splash-prev {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: $lime;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
  }
  .splash-next {
    right: -20px;
    transform: translateY(-50%);
  }
  .splash-prev {
    left: -20px;
    transform: translateY(-50%);
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
  .swiper-slide {
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s;
  }
  .swiper-slide-active {
    transform: scale(1);
    opacity: 1;
  }
  .burst {
    z-index: 3;
    top: 30%;
    @apply absolute block w-screen -translate-y-1/2 left-1/2 transform -translate-x-1/2;
    max-width: 1040px;
  }
  .net-gradient {
    @apply h-full w-10 top-0  absolute bg-black;
    background: linear-gradient(90deg, $orange 0%, transparent 100%);
  }
  .net-gradient.gradient-r {
    background: linear-gradient(-90deg, $orange 0%, transparent 100%);
  }
  @media (min-width: 1024px) {
    height: 854px;
    .hero-section-net {
      top: 65%;
      width: 150%;
    }
    .timer-digits {
      font-size: 40px;
      line-height: 60px;
      font-weight: 900;
    }
    .splash {
      align-self: flex-end;
      width: 60%;
      height: 100%;
      display: block;
    }
    .welcome-to {
      align-items: flex-start;
      width: 40%;
      max-width: 411px;
    }

    .title {
      font-size: 34px;
    }
    .title-big {
      font-size: 56px;
    }
    .connect-wallet {
      width: 100%;
      max-width: 300px;
    }
  }

  @media (min-width: 1280px) {
    .welcome-to {
      max-width: 579px;
    }
    .title {
      font-size: 46px;
    }
    .title-big {
      font-size: 66px;
    }
  }
}
