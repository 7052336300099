@import "./variables.scss";
#faq {
  // background: rgba(0, 0, 0, 0.2);

  padding-bottom: 64px;
  // background: linear-gradient(180deg, #d66703 0%, #d66703 100%);

  .my-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subheading {
    padding-bottom: 20px;
  }
  // .faq-item {
  //   @apply flex w-full items-center justify-between p-4;
  //   transition: all 0.3s;
  //   text-align: center;
  // }
  // .faq-item:hover {
  //   background: rgba(255, 255, 255, 0.1);
  // }
  .faq-question {
    max-width: 90%;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin: 0 auto;
  }
  .arrow-down {
    width: 20px;
    padding-left: 4px;
    filter: invert(1);
    transition: all 0.4s;
  }
  @media (min-width: 1024px) {
    padding-top: 30px;
    .faq-question {
      font-size: 36px;
    }
    .subheading {
      padding: 20px 0;
    }
    .arrow-down {
      width: 40px;
    }
  }

  @media (min-width: 1280px) {
    padding-bottom: 100px;
  }
}
