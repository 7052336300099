.UtilitySection {
  padding: 60px 0;
  .utility-fruit {
    transition: all 0.5s;
  }
  .utility-icon {
    transition: all 0.3s;
  }
  .utility-slide:hover {
    .utility-fruit {
      transform: scale(1.1) rotate(5deg);
    }
    .utility-icon {
      transform: scale(1.1) translateX(-50%) translateY(0%);
    }
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
  .swiper-slide {
    display: flex;

    height: 100%;
  }
  .splash {
    padding: 40px 0;
    align-self: flex-end;
    width: 100%;

    display: block;
  }
  .splash-next,
  .splash-prev {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    // background: $lime;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
  }
  .splash-next {
    right: 20px;
    transform: translateY(-50%);
  }
  .splash-prev {
    left: 20px;
    transform: translateY(-50%);
  }
  @media (min-width: 1024px) {
    .splash-next {
      right: -20px;
      transform: translateY(-50%);
    }
    .splash-prev {
      left: -20px;
      transform: translateY(-50%);
    }
  }
}
