.cards-stack {
  display: flex;
  align-items: center;
  justify-content: center;
  .card-1 {
    width: 70%;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.4s;
  }
  .card-inner {
    position: relative;
    z-index: 1;
  }
  .half-card {
    z-index: 12;

    clip-path: polygon(0 0, 59% 0, 35% 100%, 0% 100%);
  }
  .card {
    border-radius: 20px;
    overflow: hidden;
    position: absolute;
    width: 70%;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all 0.4s;
  }
  .card_2 {
    transform: translateX(-50%) translateY(-50%) rotate(6deg);
    z-index: 2;
  }
  .card_3 {
    z-index: 3;
    transform: translateX(-50%) translateY(-50%) rotate(12deg);
  }
  .card_4 {
    z-index: 4;
    transform: translateX(-50%) translateY(-50%) rotate(18deg);
  }
  .card_5 {
    z-index: 5;
    transform: translateX(-50%) translateY(-50%) rotate(24deg);
  }
  .card_6 {
    z-index: 6;
    transform: translateX(-50%) translateY(-50%) rotate(30deg);
  }
  &:hover {
    .card-1 {
      transform: translateY(-40%) scale(0.6);
    }
    .half-card {
      z-index: 11;
      transform: translateX(-50%) translateY(-90%) scale(0.6);
      clip-path: polygon(0 0, 59% 0, 35% 100%, 0% 100%);
    }
    .card {
      transform-origin: 50% 50%;
    }
    .card_2 {
      transform: translateX(-6%) translateY(-70%) rotate(60deg) scale(0.6);
    }
    .card_3 {
      transform: translateX(-8%) translateY(-15%) rotate(120deg) scale(0.6);
    }
    .card_4 {
      transform: translateX(-50%) translateY(-0%) rotate(180deg) scale(0.6);
    }
    .card_5 {
      transform: translateX(-94%) translateY(-15%) rotate(240deg) scale(0.6);
    }
    .card_6 {
      top: 50%;
      left: 50%;

      transform: translateX(-100%) translateY(-70%) rotate(300deg) scale(0.6);
    }
  }
}
