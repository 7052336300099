.heading {
  color: white;
  font-weight: 900;
  font-size: 32px;
  line-height: 1.1;
  @media (min-width: 1024px) {
    font-size: 34px;
  }
  @media (min-width: 1280px) {
    font-size: 76px;
  }
}
