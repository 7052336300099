@import url('https://fonts.googleapis.com/css2?family=Exo:wght@500;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Exo', sans-serif !important;
  background: #f1ecdf;
}

@keyframes bannermove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.my-container {
  width: 100%;
  padding: 0 40px;
  max-width: 864px;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .my-container {
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .my-container {
    max-width: 1080px;
  }
}

.faq-divider {
  @apply h-px bg-white w-full  opacity-50 flex-shrink-0;
}
.z-1 {
  z-index: 1;
}
/*Connect Wallet Section */
