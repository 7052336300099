@import "./variables.scss";
.popup {
  background: $orange;
  padding: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  .popup-mint-form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
  .popup-mint-item {
    width: 100%;
    height: 64px;
    border-radius: 16px;
    align-items: center;
    padding: 0 30px;
    text-transform: uppercase;
    background: #fff;
    display: flex;
    color: #7e8b8a;
    font-weight: 700;
    line-height: 1;
  }
  .popup-mint-button {
    color: white;
    background: $lime;
    border: none;
    justify-content: center;
  }
  .popup-close {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    line-height: 1;
    background: $lime;
    color: white;
    font-weight: 500;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1280px) {
  }
}
