#roadmap {
  .full-roadmap {
    color: white;
    font-size: 46px;
    opacity: 0;
    pointer-events: none;
    @apply fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-40 z-40 flex items-center justify-center transition-all duration-500;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }
  .show-full-button {
    cursor: pointer;

    @apply w-full max-w-xs bg-lime shadow-md hover:shadow-xl transform hover:-translate-y-1 relative  transition-all rounded-lg pt-4 pb-3 px-8 text-white uppercase font-extrabold text-xl;

    &:hover {
      background: rgb(255, 206, 46);
    }
  }
}
