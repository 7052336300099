@import "./variables.scss";
.subheading {
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 38px;
  }
}
